import React from "react";
import "./Subscribers.css";

const Subscribers = ({ users }) => {
  let registerUser = users.filter((user) => user).length - 1;

  let activeSubscribersCount = 0;
  let trialSubscribersCount = 0;
  let totalEarnings = 0;
  let currentYearEarnings = 0;
  let currentMonthEarnings = 0;
  let lastMonthEarnings = 0; // Добавлено
  let lastYearEarnings = 0; // Добавлено
  let inactiveSubscribersCount = 0;
  let newUsersThisMonth = 0; // Добавлено

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  // Расчет прошлого месяца и года
  const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
  const lastYear = currentMonth === 0 ? currentYear - 1 : currentYear;

  users.forEach((user) => {
    if (user.subscription.start.length === 0) {
      inactiveSubscribersCount++;
    }

    if (
      user.subscription.paid &&
      user.subscription.paid.length > 0 &&
      user.role === "user"
    ) {
      user.subscription.paid.forEach((payment) => {
        const paymentDate = new Date(payment.date);
        totalEarnings += payment.value;

        if (
          paymentDate.getMonth() === currentMonth &&
          paymentDate.getFullYear() === currentYear
        ) {
          currentMonthEarnings += payment.value;
          // console.log(user)
        } else if (
          paymentDate.getMonth() === lastMonth &&
          paymentDate.getFullYear() === lastYear
        ) {
          lastMonthEarnings += payment.value; // Обновлено
        }

        if (paymentDate.getFullYear() === currentYear) {
          currentYearEarnings += payment.value;
        } else if (paymentDate.getFullYear() === currentYear - 1) {
          lastYearEarnings += payment.value; // Обновлено
        }
      });
    }

    if (user.subscription && user.subscription.status && user.role === "user") {
      activeSubscribersCount++;
    }

    if (user.subscription && user.subscription.status && user.role === "test") {
      trialSubscribersCount++;
    }

    // Подсчет новых пользователей в этом месяце
    const registerDate = new Date(user.registerDate);
    if (
      registerDate.getMonth() === currentMonth &&
      registerDate.getFullYear() === currentYear
    ) {
      newUsersThisMonth++;
    }
  });

  return (
    <>
      <div className="subscribers">
        <div className="subscribers__info">
          <div className="subscribers__info-container">
            <div className="subscribers__info-number">{registerUser}</div>{" "}
            <span className="subscribers__info-text">Пользователей</span>
          </div>
          <div className="subscribers__info-container">
            <div className="subscribers__info-number">
              {activeSubscribersCount}
            </div>{" "}
            <span className="subscribers__info-text">Активных подписок</span>
          </div>
          <div className="subscribers__info-container">
            <div className="subscribers__info-number">
              {trialSubscribersCount}
            </div>{" "}
            <span className="subscribers__info-text">На пробном периоде</span>
          </div>
          <div className="subscribers__info-container">
            <div className="subscribers__info-number">
              {inactiveSubscribersCount}
            </div>{" "}
            <span className="subscribers__info-text">
              Неактивные пользователи
            </span>
          </div>
          <div className="subscribers__info-container">
            <div className="subscribers__info-number">{newUsersThisMonth}</div>{" "}
            <span className="subscribers__info-text">
              Новые пользователи в этом месяце
            </span>
          </div>
        </div>
        <div className="subscribers__info">
          <div className="subscribers__info-container">
            <div className="subscribers__info-number">
              {totalEarnings.toFixed(0)}$
            </div>{" "}
            <span className="subscribers__info-text">Общий заработок</span>
          </div>
          <div className="subscribers__info-container">
            <div className="subscribers__info-number">
              {currentYearEarnings.toFixed(0)}$
            </div>{" "}
            <span className="subscribers__info-text">
              Заработок в этом году
            </span>
          </div>
          <div className="subscribers__info-container">
            <div className="subscribers__info-number">
              {lastYearEarnings.toFixed(0)}$
            </div>{" "}
            <span className="subscribers__info-text">
              Заработок в прошлом году
            </span>
          </div>
          <div className="subscribers__info-container">
            <div className="subscribers__info-number">
              {currentMonthEarnings.toFixed(0)}$
            </div>{" "}
            <span className="subscribers__info-text">
              Заработок в этом месяце
            </span>
          </div>
          <div className="subscribers__info-container">
            <div className="subscribers__info-number">
              {lastMonthEarnings.toFixed(0)}$
            </div>{" "}
            <span className="subscribers__info-text">
              Заработок в прошлом месяце
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribers;
