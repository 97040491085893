import "./App.css";
import mainApi from "../../utils/api.js";
import CurrentUserContext from "../../contexts/CurrentUserContext.jsx";
import { useState, useEffect, useCallback, useMemo } from "react";
import {
  Navigate,
  useRoutes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Navigation from "../Navigation/Navigation.jsx";
import Subscribers from "../Subscribers/Subscribers.jsx";
import UserList from "../UserList/UserList.jsx";
import ButtonList from "../ButtonList/ButtonList.jsx";
import OtherButtonList from "../OtherButtonList/OtherButtonList.jsx";
import AddButtons from "../AddButton/AddButton.jsx";
import Login from "../Login/Login.jsx";
import InfoTooltip from "../InfoTooltip/InfoTooltip.jsx";
import Preloader from "../Preloader/Preloader.jsx";

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();

  // Объединяем связанные состояния в один объект
  const [buttonState, setButtonState] = useState({
    buttons: [],
    ppButtons: [],
    aiButtons: [],
    psButtons: [],
  });

  const [appState, setAppState] = useState({
    authChecked: false,
    isLoader: false,
    load: false,
    loggedIn: false,
    serverError: null,
    isButtonsUpdated: false,
    isUsersUpdated: false,
  });

  const [isInfoTooltip, setIsInfoTooltip] = useState({
    isOpen: false,
    status: true,
    text: "",
  });

  const [currentUser, setCurrentUser] = useState({});
  const [users, setUsers] = useState([]);

  // Мемоизируем функции обработки событий
  const closeInfoTooltip = useCallback(() => {
    setIsInfoTooltip((prev) => ({ ...prev, isOpen: false }));
  }, []);

  const handleLogin = useCallback(
    async ({ email, password }) => {
      setAppState((prev) => ({ ...prev, isLoader: true }));
      try {
        const jwt = await mainApi.login({ email, password });
        if (!jwt.token) throw new Error("Token is not present in response");

        localStorage.setItem("jwt", jwt.token);
        const userData = await mainApi.getUserInfo();

        if (userData.role !== "admin") {
          localStorage.removeItem("jwt");
          setAppState((prev) => ({ ...prev, loggedIn: false }));
          throw new Error("You are not authorized to access this application");
        }

        setCurrentUser(userData);
        setAppState((prev) => ({ ...prev, loggedIn: true }));
        navigate("/");
      } catch (err) {
        setAppState((prev) => ({ ...prev, serverError: err.message }));
      } finally {
        setAppState((prev) => ({ ...prev, isLoader: false }));
      }
    },
    [navigate]
  );

  // Оптимизированные функции для работы с кнопками
  const handleButtonOperation = useCallback(
    async (operation, buttonId = null, buttonData = null, type) => {
      try {
        let result;
        const buttonTypeMap = {
          buttons: "buttons",
          ppbuttons: "ppButtons",
          aibuttons: "aiButtons",
          psbuttons: "psButtons",
        };
        const stateKey = buttonTypeMap[type];

        switch (operation) {
          case "create":
            result = await mainApi.createButton(buttonData, type);
            setButtonState((prev) => ({
              ...prev,
              [stateKey]: [...prev[stateKey], result],
            }));
            break;
          case "update":
            result = await mainApi.updateButton(buttonId, buttonData, type);
            setButtonState((prev) => ({
              ...prev,
              [stateKey]: prev[stateKey].map((button) =>
                button._id === buttonId ? result : button
              ),
            }));
            break;
          case "delete":
            await mainApi.deleteButton(buttonId, type);
            setButtonState((prev) => ({
              ...prev,
              [stateKey]: prev[stateKey].filter(
                (button) => button._id !== buttonId
              ),
            }));
            break;
        }

        setIsInfoTooltip({
          isOpen: true,
          status: true,
          text: `Кнопка ${
            operation === "create"
              ? "создана"
              : operation === "update"
              ? "обновлена"
              : "удалена"
          }`,
        });
      } catch (err) {
        setIsInfoTooltip({ isOpen: true, status: false, text: err.message });
      }
    },
    []
  );

  // Оптимизированные функции для работы с пользователями
  const handleUserOperation = useCallback(
    async (operation, userId, updateData = null) => {
      try {
        switch (operation) {
          case "updateSubscription":
            await mainApi.updateSubscription(userId, updateData);
            setAppState((prev) => ({ ...prev, isUsersUpdated: true }));
            setIsInfoTooltip({
              isOpen: true,
              status: true,
              text: "Подписка Обновлена",
            });
            break;
          case "signOut":
            await mainApi.updateSubscription(userId, {
              license: { status: false, hostName: "", userName: "" },
            });
            setAppState((prev) => ({ ...prev, isUsersUpdated: true }));
            setIsInfoTooltip({
              isOpen: true,
              status: true,
              text: "Лицензия деактивированна",
            });
            break;
          case "delete":
            await mainApi.deleteUser(userId);
            setUsers((prev) => prev.filter((user) => user._id !== userId));
            setIsInfoTooltip({
              isOpen: true,
              status: true,
              text: "Пользователь удален",
            });
            break;
        }
      } catch (err) {
        setIsInfoTooltip({ isOpen: true, status: false, text: err.message });
      }
    },
    []
  );

  // Эффект для проверки авторизации
  useEffect(() => {
    const checkAuth = async () => {
      const jwt = localStorage.getItem("jwt");
      if (!jwt) {
        setAppState((prev) => ({ ...prev, authChecked: true }));
        return;
      }

      try {
        setAppState((prev) => ({ ...prev, isLoader: true }));
        const userData = await mainApi.getUserInfo();

        if (userData.role !== "admin") {
          localStorage.removeItem("jwt");
          throw new Error("You are not authorized to access this application");
        }

        setCurrentUser(userData);
        setAppState((prev) => ({
          ...prev,
          loggedIn: true,
          authChecked: true,
        }));

        if (location.pathname === "/signin") {
          navigate("/");
        }
      } catch (err) {
        setIsInfoTooltip({ isOpen: true, status: false, text: err.message });
        setAppState((prev) => ({ ...prev, authChecked: true }));
      } finally {
        setAppState((prev) => ({ ...prev, isLoader: false }));
      }
    };

    checkAuth();
  }, [navigate, location.pathname]);

  // Эффект для загрузки данных пользователей
  useEffect(() => {
    if (!appState.loggedIn) return;

    const fetchUsers = async () => {
      try {
        setAppState((prev) => ({ ...prev, isLoader: true }));
        const fetchedUsers = await mainApi.getUsers();
        setUsers(fetchedUsers);
        setAppState((prev) => ({ ...prev, isUsersUpdated: false }));
      } catch (err) {
        setIsInfoTooltip({ isOpen: true, status: false, text: err.message });
      } finally {
        setAppState((prev) => ({ ...prev, isLoader: false }));
      }
    };

    fetchUsers();
  }, [appState.loggedIn, appState.isUsersUpdated]);

  // Эффект для загрузки кнопок
  useEffect(() => {
    if (!appState.loggedIn) return;

    const fetchButtons = async () => {
      setAppState((prev) => ({ ...prev, isLoader: true, load: true }));

      try {
        const [buttons, ppButtons, aiButtons, psButtons] = await Promise.all([
          mainApi.getButtons("buttons"),
          mainApi.getButtons("ppbuttons"),
          mainApi.getButtons("aibuttons"),
          mainApi.getButtons("psbuttons"),
        ]);

        setButtonState({
          buttons,
          ppButtons,
          aiButtons,
          psButtons,
        });
      } catch (err) {
        setIsInfoTooltip({ isOpen: true, status: false, text: err.message });
      } finally {
        setAppState((prev) => ({
          ...prev,
          isLoader: false,
          load: false,
          isButtonsUpdated: false,
        }));
      }
    };

    fetchButtons();
  }, [appState.loggedIn, appState.isButtonsUpdated]);

  // Мемоизируем роуты
  const routes = useMemo(
    () => [
      {
        path: "signin",
        element: appState.authChecked ? (
          appState.loggedIn ? (
            <Navigate to="/" />
          ) : (
            <Login
              handleLogin={handleLogin}
              serverError={appState.serverError}
            />
          )
        ) : null,
      },
      {
        path: "/",
        element: appState.authChecked ? (
          appState.loggedIn ? (
            <>
              <Navigation />
              <Subscribers users={users} />
              <UserList
                users={users}
                handleSubscription={(userId, data) =>
                  handleUserOperation("updateSubscription", userId, data)
                }
                handleDeleteUser={(userId) =>
                  handleUserOperation("delete", userId)
                }
                handleSignOut={(userId) =>
                  handleUserOperation("signOut", userId)
                }
              />
            </>
          ) : (
            <Navigate to="/signin" />
          )
        ) : null,
      },
      {
        path: "buttons",
        element: appState.authChecked ? (
          appState.loggedIn ? (
            <>
              <Navigation />
              <ButtonList
                buttons={buttonState.buttons}
                handleCreateButton={(data) =>
                  handleButtonOperation("create", null, data, "buttons")
                }
                handleUpdateButton={(id, data) =>
                  handleButtonOperation("update", id, data, "buttons")
                }
                handleDeleteButton={(id) =>
                  handleButtonOperation("delete", id, null, "buttons")
                }
              />
              <AddButtons />
            </>
          ) : (
            <Navigate to="/signin" />
          )
        ) : null,
      },
      {
        path: "ppbuttons",
        element: appState.authChecked ? (
          appState.loggedIn ? (
            <>
              <Navigation />
              <OtherButtonList
                buttons={buttonState.ppButtons}
                handleCreateButton={(data) =>
                  handleButtonOperation("create", null, data, "ppbuttons")
                }
                handleUpdateButton={(id, data) =>
                  handleButtonOperation("update", id, data, "ppbuttons")
                }
                handleDeleteButton={(id) =>
                  handleButtonOperation("delete", id, null, "ppbuttons")
                }
              />
            </>
          ) : (
            <Navigate to="/signin" />
          )
        ) : null,
      },
      {
        path: "aibuttons",
        element: appState.authChecked ? (
          appState.loggedIn ? (
            <>
              <Navigation />
              <OtherButtonList
                buttons={buttonState.aiButtons}
                handleCreateButton={(data) =>
                  handleButtonOperation("create", null, data, "aibuttons")
                }
                handleUpdateButton={(id, data) =>
                  handleButtonOperation("update", id, data, "aibuttons")
                }
                handleDeleteButton={(id) =>
                  handleButtonOperation("delete", id, null, "aibuttons")
                }
              />
            </>
          ) : (
            <Navigate to="/signin" />
          )
        ) : null,
      },
      {
        path: "psbuttons",
        element: appState.authChecked ? (
          appState.loggedIn ? (
            <>
              <Navigation />
              <OtherButtonList
                buttons={buttonState.psButtons}
                handleCreateButton={(data) =>
                  handleButtonOperation("create", null, data, "psbuttons")
                }
                handleUpdateButton={(id, data) =>
                  handleButtonOperation("update", id, data, "psbuttons")
                }
                handleDeleteButton={(id) =>
                  handleButtonOperation("delete", id, null, "psbuttons")
                }
              />
            </>
          ) : (
            <Navigate to="/signin" />
          )
        ) : null,
      },
    ],
    [
      appState.authChecked,
      appState.loggedIn,
      appState.serverError,
      users,
      handleLogin,
      handleUserOperation,
    ]
  );

  const routing = useRoutes(routes);

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <div>
        <InfoTooltip onClose={closeInfoTooltip} isInfoTooltip={isInfoTooltip} />
        {appState.isLoader ? <Preloader isOpen={!appState.load} /> : routing}
      </div>
    </CurrentUserContext.Provider>
  );
}
