import "./UserPopup.css";
import { useNavigate } from "react-router-dom";
import Invoice from "../Invoice/Invoice.jsx";

import { formatDate, formatTime } from "../../utils/utils.js";
import { addDays, addMonths, addYears } from "date-fns";
import React, { useState, useEffect } from "react";

const UserPopup = ({ user, onClose, handleSubscription, handleSignOut }) => {
  const navigate = useNavigate();
  const { name, family, login, subscription, license, role } = user;
  const { status, end, ae, pp, mg, am, dr, gt } = subscription;

  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedPaid, setSelectedPaid] = useState(null);
  const [showInvoice, setShowInvoice] = useState(false);

  const handleTimeClick = (key) => {
    setSelectedTime((prevSelectedTime) =>
      prevSelectedTime === key ? null : key
    );
  };

  const handleDeactivate = () => {
    handleSignOut(user._id);
  };

  const handlePaidClick = (key) => {
    setSelectedPaid((prevSelectedTime) =>
      prevSelectedTime === key ? null : key
    );
  };

  const [categories, setCategories] = useState([
    { key: "AE", value: ae },
    { key: "PP", value: pp },
    { key: "MG", value: mg },
    { key: "AM", value: am },
    { key: "DR", value: dr },
    { key: "GT", value: gt },
  ]);

  const handleCategoryClick = (key) => {
    setCategories((prevState) =>
      prevState.map((category) => {
        if (category.key === key) {
          return { ...category, value: !category.value };
        }
        return category;
      })
    );
  };

  const handleUpdateClick = () => {
    // Получаем текущую дату
    let start = new Date();
    let time = 1;

    // Получаем последнюю дату окончания подписки
    let currentEnd = end[end.length - 1];
    let newEnd;

    // Если подписки нет или она уже закончилась, начинаем отсчет от текущего момента
    if (!currentEnd || new Date(currentEnd) < start) {
      newEnd = start;
    } else {
      // Если подписка еще активна, продлеваем ее
      newEnd = new Date(currentEnd);
    }

    // Добавляем к текущему сроку подписки выбранное время
    switch (selectedTime) {
      case "Day":
        newEnd = addDays(newEnd, 1);
        break;
      case "1 Week":
        newEnd = addDays(newEnd, 7);
        break;
      case "2 Week":
        newEnd = addDays(newEnd, 14);
        break;
      case "Month":
        newEnd = addMonths(newEnd, 1);
        time = 1;
        break;
      case "2 Month":
        newEnd = addMonths(newEnd, 2);
        time = 2;
        break;
      case "Year":
        newEnd = addYears(newEnd, 1);
        time = 12;
        break;
      default:
        break;
    }

    // Получаем значения для категорий
    const aeValue = categories.find((cat) => cat.key === "AE").value;
    const ppValue = categories.find((cat) => cat.key === "PP").value;
    const mgValue = categories.find((cat) => cat.key === "MG").value;
    const amValue = categories.find((cat) => cat.key === "AM").value;
    const drValue = categories.find((cat) => cat.key === "DR").value;
    const gtValue = categories.find((cat) => cat.key === "GT").value;

    // Проверяем, активна ли подписка
    const isStatusTrue = aeValue || ppValue || mgValue || amValue || drValue;

    const updatedStart = [...user.subscription.start, start.toISOString()];
    const updatedEnd = [...user.subscription.end, newEnd.toISOString()];
    const updatedPaid = [
      ...user.subscription.paid,
      { date: start.toISOString(), value: selectedPaid * time },
    ];

    // Инициализируем объект updateData с обязательными полями
    const updateData = {
      // telegram:{
      //   username:'Solomon_K1ng'
      // },
      // emailVerifier:{
      //   verify:false
      // },
      subscription: {
        status: isStatusTrue,
        ae: aeValue,
        pp: ppValue,
        mg: mgValue,
        am: amValue,
        dr: drValue,
        gt: gtValue,
      },
    };

    // updateData.role = 'test';
    // updateData.password = "DiRJvY5Rzhat";

    // Проверяем условия для добавления дополнительных полей
    if (
      user.subscription.end[user.subscription.end.length - 1] !==
      newEnd.toISOString()
    ) {
      updateData.subscription.start = updatedStart;
      updateData.subscription.end = updatedEnd;
    }

    if (selectedPaid !== null && selectedPaid !== 0) {
      updateData.subscription.paid = updatedPaid;
    }

    // Обновляем подписку
    handleSubscription(user._id, updateData);

    // Закрываем попап
    onClose();
  };

  const handleTelegram = () => {
    const updateData = {
      telegram: {
        id: "",
        username: "",
        isLinked: false,
      },
    };

    // Обновляем подписку
    handleSubscription(user._id, updateData);

    // Закрываем попап
    onClose();
  };

  return (
    <>
      <div className="user-popup__overlay" onClick={onClose}></div>
      <div className="user-popup">
        {showInvoice ? (
          // Рендер компонента Invoice (замените <InvoiceComponent /> на ваш компонент)
          <Invoice
            user={user}
            categories={categories}
            time={selectedTime || 1}
          />
        ) : (
          <>
            <div className="user-card__title">{name + " " + family}</div>
            {license.status ? (
              <span className="user-card__subscription_end">
                {license.hostName}
              </span>
            ) : null}
            <span className="user-card__subscription_end">
              {formatDate(end[end.length - 1]) +
                " - " +
                formatTime(end[end.length - 1])}
            </span>

            <div className="user-card__time">
              {["Day", "1 Week", "2 Week", "Month", "2 Month", "Year"].map(
                (timeKey) => (
                  <div
                    key={timeKey}
                    className={
                      selectedTime === timeKey
                        ? "user-popup__category-active"
                        : "user-popup__category-inactive"
                    }
                    onClick={() => handleTimeClick(timeKey)}
                  >
                    {timeKey}
                  </div>
                )
              )}
            </div>

            <div className="user-card__category">
              {categories.map((category) => (
                <div
                  key={category.key}
                  className={
                    category.value
                      ? "user-popup__category-active"
                      : "user-popup__category-inactive"
                  }
                  onClick={() => handleCategoryClick(category.key)}
                >
                  {category.key}
                </div>
              ))}
            </div>

            <div className="user-card__time">
              {[15, 25, 30, 45, 50, 60, 100].map((paidKey) => (
                <div
                  key={paidKey}
                  className={
                    selectedPaid === paidKey
                      ? "user-popup__category-active"
                      : "user-popup__category-inactive"
                  }
                  onClick={() => handlePaidClick(paidKey)}
                >
                  {paidKey + "$"}
                </div>
              ))}
            </div>
            <button
              onClick={handleUpdateClick}
              className="user-popup__update-btn"
            >
              Обновить
            </button>
            {/* Кнопка Invoice */}
            <button onClick={() => setShowInvoice(true)}>Invoice</button>
            <button
              onClick={handleDeactivate}
              className="user-popup__update-btn"
            >
              Завершить сеанс
            </button>
            <button onClick={handleTelegram} className="user-popup__update-btn">
              Сброс Телеграмм
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default UserPopup;
