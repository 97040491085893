import React, { useState, useEffect } from "react";
import "./UserList.css";
import UserCard from "../UserCard/UserCard.jsx";
import mainApi from "../../utils/api.js";

const UserList = ({
  users,
  handleSubscription,
  handleDeleteUser,
  handleSignOut,
}) => {
  const [filter, setFilter] = useState(
    localStorage.getItem("userFilter") || "all"
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);

  useEffect(() => {
    localStorage.setItem("userFilter", filter);
  }, [filter]);

  useEffect(() => {
    const filtered = users
      .filter((user) => {
        if (filter === "subscribed") return user.subscription.status;
        if (filter === "notSubscribed") return !user.subscription.status;
        return true;
      })
      .sort((a, b) => {
        if (filter === "subscribed") {
          const aEndDates = a.subscription.end || [];
          const bEndDates = b.subscription.end || [];
          if (aEndDates.length > 0 && bEndDates.length > 0) {
            const aLastEndDate = new Date(aEndDates[aEndDates.length - 1]);
            const bLastEndDate = new Date(bEndDates[bEndDates.length - 1]);
            return aLastEndDate - bLastEndDate;
          }
        }
        return 0;
      });

    setFilteredUsers(filtered);
  }, [users, filter]);

  const searchedUsers =
    searchQuery === ""
      ? filteredUsers
      : users.filter((user) => {
          const searchFields = [
            user.name,
            user.family,
            user.login,
            user.email,
          ].map((field) => field?.toLowerCase() || "");
          return searchFields.some((field) =>
            field.includes(searchQuery.toLowerCase())
          );
        });

  const subscriptionCount = users.filter(
    (user) => user.subscription.status
  ).length;
  const noSubscriptionCount = users.filter(
    (user) => !user.subscription.status
  ).length;

  return (
    <section className="users-card">
      <input
        type="text"
        className="users-card__search"
        placeholder="Поиск по имени, фамилии, логину или email"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      {/* {searchQuery && (
          <p>
            Найдено: {searchedUsers.length} из {users.length}
          </p>
        )} */}
      <div className="filter-buttons">
        <div
          className={`filter-buttons__button ${
            filter === "all" ? "filter-buttons__button_active" : ""
          }`}
          onClick={() => setFilter("all")}
        >
          Все пользователи
        </div>
        <div
          className={`filter-buttons__button ${
            filter === "subscribed" ? "filter-buttons__button_active" : ""
          }`}
          onClick={() => setFilter("subscribed")}
        >
          С подпиской
        </div>
        <div
          className={`filter-buttons__button ${
            filter === "notSubscribed" ? "filter-buttons__button_active" : ""
          }`}
          onClick={() => setFilter("notSubscribed")}
        >
          Без подписки
        </div>
      </div>
      {searchedUsers.length ? (
        <ul className="users-card__list">
          {searchedUsers.map((data) => (
            <UserCard
              key={data._id}
              user={data}
              handleSubscription={handleSubscription}
              handleDeleteUser={handleDeleteUser}
              handleSignOut={handleSignOut}
            />
          ))}
        </ul>
      ) : (
        <p>Пользователи не найдены</p>
      )}
    </section>
  );
};

export default UserList;
